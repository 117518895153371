"use client";
import { createPortal } from "react-dom";
import React, { useEffect, useRef } from "react";

type Props = {
  selector: string;
  children: React.ReactNode;
  mode: "append" | "replace";
};

export function Portal({ selector, children, mode = "replace" }: Props) {
  const isParentEmpty = useRef<boolean>(false);

  const parent = useRef<HTMLElement | null>(
    document.querySelector(selector),
  ).current;

  if (!parent) {
    return null;
  }
  useEffect(() => {
    if (mode === "append") return;
    if (parent.children.length === 0) return;
    parent.innerHTML = "";
    isParentEmpty.current = true;

    return () => {
      isParentEmpty.current = false;
    }
  }, []);

  mode === "append"
    ? createPortal(children, parent)
    : isParentEmpty && createPortal(children, parent);
}
